<template lang="html">
  <div class="please-wait">
    <h3>
      <IxRes>automaticZoneMaps.headers.pleaseWait</IxRes>
    </h3>

    <p>
      <IxRes>automaticZoneMaps.hints.furtherSteps</IxRes>
    </p>

    <strong>
      <IxRes>automaticZoneMaps.hints.frsUsePossible</IxRes>
    </strong>

    <div class="button-container">
      <IxButton large icon="map" @click="gotoMapManagement">
        <IxRes>automaticZoneMaps.buttons.toMapManagement</IxRes>
      </IxButton>
      <IxButton
        v-if="debugMode"
        large back
        @click="goBackToAutomaticMzk"
      >
        <IxRes>automaticZoneMaps.buttons.goBackToAutomaticMzk</IxRes>
      </IxButton>
    </div>

    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import IxButton from '@components/IxButton'

import {config} from 'src/js/infrastructure'

export default {
  components: {
    FrsLoadingIndicator,
    IxButton
  },
  mixins: [
    MirrorRouteMixin
  ],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    debugMode () {
      return config.debugMode
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/automaticZoneMaps', [
      'loadPrerequisiteData'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    gotoMapManagement () {
      this.loading = true
      this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
    },
    goBackToAutomaticMzk () {
      this.loading = true
      return this.loadPrerequisiteData().then(() => {
        this.$router.push({name: 'automaticZoneMaps'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.please-wait {
  display: flex;
  flex-direction: column;

  padding: 1em;

  > h3 {
    margin-top: 0;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 5px;
  }
  margin-top: 1em;
}
</style>
